import { UserInfoAdapter } from '~/repository/modules/mainPage/MainPage.Adapters';
import type { IUserInfoEntity } from '~/repository/modules/mainPage/MainPageEntities.types';
import { useUserStore } from '~/store/user/user.store';

/* Хранилище контроллера ежедневных бесплатных кейсов */
export const useDailyCasesStore = defineStore('mainPage/dailyCases', () => {
  const {
    $api: { mainPage: MainPageService },
  } = useNuxtApp();

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  /* Информация о юзере */
  const userInfo = ref<Partial<IUserInfoEntity>>({});

  const generateLevel = async () => {
    try {
      const result = await MainPageService.generateUserLevel();
      if (!result) return false;

      await userStore.getMe();
      return true;
    } catch {
      return false;
    }
  };

  watch(
    user,
    () => {
      userInfo.value = user.value ? UserInfoAdapter(user.value) : {};
    },
    {
      deep: true,
      immediate: true,
    },
  );

  return {
    generateLevel,
    userInfo,
  };
});
